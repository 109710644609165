<template>
  <b-modal
    v-model="isModalShow"
    title="Please Add Conversation Topic"
    hide-footer
    @hide="hide"
    size="md"
    centered
  >
    <div>
      <InputSelect
        title="Topic"
        valueField="id"
        textField="name"
        v-bind:options="topicList"
        v-on:onDataChange="onDataChange"
        v-model="form.topicId"
        isRequired
        v-bind:isValidate="$v.form.topicId.$error"
        :v="$v.form.topicId"
      />
      <b-row>
        <b-col class="text-right">
          <b-button variant="outline-primary" @click="hide">
            Cancel
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="main-primary" @click="selectConversationTopic">
            Comfirm
          </b-button></b-col
        >
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import { mapGetters } from "vuex";
import { required, minValue } from "vuelidate/lib/validators";
export default {
  name: "ChatBoxModalConversationTopic",
  props: {
    chatRoomId: {
      required: false,
      type: Array
    },
    selectedTopicId: {
      required: false,
      type: [String, Number]
    }
  },
  components: {
    InputSelect
  },
  data() {
    return {
      forceRefresh: true,
      loadFlag: false,
      isModalShow: false,
      form: {
        topicId: 0
      },
      mode: 0
    };
  },
  validations() {
    return {
      form: {
        topicId: {
          required,
          minValue: minValue(1)
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      brandId: "getBrand",
      topicList: "getConversationTopicList"
    })
  },
  methods: {
    hide() {
      this.isModalShow = false;
    },
    show(mode) {
      this.mode = mode;
      this.isModalShow = true;
    },
    onDataChange(val) {
      this.form.topicId = val;
    },
    selectConversationTopic() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.selelctConversationTopic();
    },
    async selelctConversationTopic() {
      await this.$emit("selectedTopic", this.form.topicId);
      if (!this.selectedTopicId) await this.$emit("endChat", this.mode);
      await this.hide();
    }
  }
};
</script>

<style lang="scss" scoped>
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
::v-deep .col-ids {
  width: 105px !important;
  max-width: 105px !important;
  button {
    min-width: 80px;
  }
}
.btn-outline-primary {
  min-width: 150px;
}
@media (max-width: 767.98px) {
  ::v-deep .table-main {
    .col-name {
      min-width: 190px;
    }
  }
}
</style>
